import React, { useState, useEffect, useCallback, useRef } from 'react';

import { animated } from 'react-spring';
import { Container, Content, ComboProps } from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Button from 'components/Button';

import RadioContainer from 'components/RadioContainer';

import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { format, isValid, getMonth, subDays, addYears } from 'date-fns';
// import ptBR from 'date-fns/locale/pt-BR';
import { addDays, parseISO } from 'date-fns/esm';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { convertSpecialChars, deconvertSpecialChars } from 'utils/specialChars';
import getValidationErrors from 'utils/getValidationErrors';
import { useSpring } from 'react-spring';
import { FaTimes } from 'react-icons/fa';
// import {
//   deleteLocalStorageItemKey,
//   setLocalStorage,
// } from 'utils/handleLocalStorage';

import {
  generateTodayV2,
  handleTimeZone,
  serviceAllowedDate,
  stringToDate,
} from 'utils/formatDate';
import moment from 'moment';

import { InfoContainer, ButtonRemove } from './styles';

import {
  CoordANB,
  CoordLOC,
  CoordGUIA,
  ListProps,
  ComboGEvangProps,
  ComboGuiaProps,
  ComboLocalServProps,
  UpdateFormData,
  StateProps,
} from '../interfaces';

const AtividadesUpdate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation<StateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [coordANBS, setCoordANBS] = useState<CoordANB[]>([]);
  const [coordLOCS, setCoordLOCS] = useState<CoordLOC[]>([]);
  const [coordGUIAS, setCoordGUIAS] = useState<CoordGUIA[]>([]);
  const [filteredCoordLOCS, setFilteredCoordLOCS] = useState<CoordLOC[]>([]);
  const [filteredCoordGUIAS, setFilteredCoordGUIAS] = useState<CoordGUIA[]>([]);

  const lsConst = location.pathname.includes('atividades')
    ? process.env.REACT_APP_ATIVIDADE
    : location.pathname.includes('servicos')
    ? process.env.REACT_APP_SERVICO
    : undefined;

  const [activity] = useState<ListProps>(() => {
    if (!location.state) {
      return {} as ListProps;
    }

    return location.state.activity;
  });

  const [comboANBs, setComboANBs] = useState<ComboProps[]>([]);

  const [prevOrig] = useState(() => {
    if (Object.keys(activity).length > 0) {
      if (user.anbc !== activity.orig.anb) {
        return 'E';
      }
      if (user.loccod !== activity.orig.loc) {
        return 'N';
      }
    }

    return 'L';
  });
  const [selectedOrig, setSelectedOrig] = useState(() => {
    if (Object.keys(activity).length > 0) {
      if (user.anbc !== activity.orig.anb) {
        return 'E';
      }
      if (user.loccod !== activity.orig.loc) {
        return 'N';
      }
    }

    return 'L';
  });
  const [selectedANB, setSelectedANB] = useState('');
  const [selectedLocalServ, setSelectedLocalServ] = useState('Selecione');
  const [initSemana, setInitSemana] = useState('Selecione o dia da semana');

  const [comboLocalServ, setComboLocalServ] = useState<ComboProps[]>([]);

  const [gevangs, setGevangs] = useState<ComboGEvangProps[]>([]);
  const [comboGevang, setComboGevang] = useState<ComboProps[]>([]);
  const [guias, setGuias] = useState<ComboGuiaProps[]>([]);
  const [enterGuias, setEnterGuias] = useState<ComboGuiaProps[]>([]);
  const [comboGuia, setComboGuia] = useState<ComboProps[]>([]);
  const [comboGuia2, setComboGuia2] = useState<ComboProps[]>([]);

  const [initialG, setInitialG] = useState('Selecione');
  const [initialG2, setInitialG2] = useState('Selecione');
  const [initialGEvang, setInitialGEvang] = useState('Selecione');

  const [partType, setPartType] = useState(() => {
    return ['4', '5', '6'].indexOf(activity.prqcod) < 0 ? 'P' : 'V';
  });

  const semana: ComboProps[] = [
    { value: '1', label: 'Segunda' },
    { value: '2', label: 'Terça' },
    { value: '3', label: 'Quarta' },
    { value: '4', label: 'Quinta' },
    { value: '5', label: 'Sexta' },
    { value: '6', label: 'Sábado' },
    { value: '0', label: 'Domingo' },
  ];

  const otherNac = useSpring({
    height: selectedOrig === 'E' ? '70px' : '0px',
    pointerEvents: selectedOrig === 'E' ? 'all' : 'none',
    opacity: selectedOrig === 'E' ? 1 : 0,
  });

  const buttonStyle = useSpring({
    opacity: initialG2 !== 'Selecione' ? 1 : 0,
    transform:
      initialG2 !== 'Selecione' ? 'translateX(0px)' : 'translateX(-10px)',
    pointerEvents: initialG2 !== 'Selecione' ? 'all' : 'none',
  });

  const getANBs = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');

    setComboANBs(
      response.data.filter((item: ComboProps) => item.value !== user.anbc),
    );

    //
  }, [user.anbc]);

  const getGuias = useCallback(
    async (param?: string) => {
      const response = await api.get(
        `/combos/comboGuias.php?data=${JSON.stringify({
          filter: param || 'byLoc',
        })}`,
      );
      // const locFiltered: ComboGuiaProps[] = response.data.filter(
      //   (item: ComboGuiaProps) =>
      //     ['SA', 'SJ'].indexOf(activity.tscod) > -1
      //       ? item.tg === 'FG'
      //       : ['GA', 'GP'].indexOf(item.tg) > -1,
      // );
      // MUDANÇA EM 14/03/2022, conforme solicitado;

      const locFiltered: ComboGuiaProps[] = response.data.filter(
        (item: ComboGuiaProps) =>
          ['SA', 'SJ'].indexOf(activity.tscod) > -1
            ? item.tg === 'FG'
            : ['GA'].indexOf(item.tg) > -1,
      );

      if (locFiltered.length === 0) {
        addToast({
          type: 'info',
          title: 'Não há Futuros Guias cadastrados na coordenação.',
          description: 'Não será possível cadastrar uma Oficina Supervisionada',
          seconds: 8,
        });
        return;
      }

      if (enterGuias.length === 0) {
        setEnterGuias(locFiltered);
      }

      setGuias(locFiltered);

      const guiaIndex = locFiltered.findIndex((item: ComboGuiaProps) =>
        ['JE', 'PE', 'RD'].indexOf(activity.tscod) > -1
          ? item.seq === activity.svcaplic
          : item.seq === activity.guia1,
      );

      // setInitialG(
      //   guiaIndex >= 0
      //     ? `${locFiltered[guiaIndex].nome}${
      //         param ? `, ${locFiltered[guiaIndex].loc}` : ''
      //       }`
      //     : 'Selecione',
      // );
      setInitialG(guiaIndex >= 0 ? locFiltered[guiaIndex].seq : 'Selecione');

      setComboGuia(
        locFiltered.map((item: ComboGuiaProps) => ({
          value: item.seq,
          label: param === 'byNac' ? `${item.nome}, ${item.loc}` : item.nome,
        })),
      );

      if (['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(activity.tscod) > -1) {
        const guia2Index = locFiltered.findIndex(
          (item: ComboGuiaProps) => item.seq === activity.guia2,
        );

        if (guia2Index >= 0) {
          setInitialG2(locFiltered[guia2Index].seq);
          formRef.current?.setFieldValue(
            'comboGuia2',
            locFiltered[guia2Index].seq,
          );
        } else {
          setInitialG2(activity.guia2 ? activity.guia2 : 'Selecione');
        }

        setComboGuia2(
          locFiltered
            .filter((item: ComboGuiaProps) => item.seq !== activity.guia1)
            .map((item: ComboGuiaProps) => ({
              value: item.seq,
              label: item.nome,
            })),
        );
      }
    },
    [
      activity.guia1,
      activity.guia2,
      activity.svcaplic,
      activity.tscod,
      addToast,
      enterGuias.length,
    ],
  );

  const getOtherGuias = useCallback(
    async (param: string) => {
      const response = await api.get(
        `/combos/comboGuias.php?data=${JSON.stringify({
          filter: 'byNac',
          anbc: param,
        })}`,
      );

      // const locFiltered: ComboGuiaProps[] = response.data.filter(
      //   (item: ComboGuiaProps) => ['GA', 'GP'].indexOf(item.tg) > -1,
      // );
      // MUDANÇA EM 14/03/2022, conforme solicitado;
      const locFiltered: ComboGuiaProps[] = response.data.filter(
        (item: ComboGuiaProps) => ['GA', 'GP'].indexOf(item.tg) > -1,
      );

      const index = locFiltered.findIndex(
        (item: ComboGuiaProps) => item.seq === activity.svcaplic,
      );

      if (index >= 0) {
        setInitialG(locFiltered[index].seq);
      } else if (activity.anbcod !== param) {
        setInitialG('Selecione');
      } else {
        setInitialG(locFiltered[index].seq);
      }

      setGuias(locFiltered);

      if (enterGuias.length === 0) {
        setEnterGuias(locFiltered);
      }

      setComboGuia(
        locFiltered.map((item: ComboGuiaProps) => ({
          value: item.seq,
          label: `${item.nome}, ${item.loc}`,
        })),
      );
    },
    [activity.anbcod, activity.svcaplic, enterGuias.length],
  );

  const getGevangs = useCallback(async () => {
    const response = await api.get('/combos/comboGEvangs.php');

    const responseFiltered = response.data
      // .filter((item: ComboGEvangProps) => item.anb === user.anbc)
      .filter((item: ComboGEvangProps) => {
        if (['CC', 'RC'].includes(activity.tscod)) {
          return item.tipo === 'CC' && item.anb === user.anbc;
        }
        if (activity.tscod === 'ED') {
          return item.tipo === 'CE';
        }
        if (activity.tscod === 'RE') {
          return item.tipo === 'AR';
        }
        return item;
      });

    const index = responseFiltered.findIndex((item: ComboGEvangProps) =>
      activity.tscod === 'CC'
        ? item.seq === activity.ofiguia
        : item.seq === activity.svcaplic,
    );

    setInitialGEvang(index >= 0 ? responseFiltered[index].seq : activity.seq);
    setGevangs(responseFiltered);

    setComboGevang(
      responseFiltered.map((item: ComboGEvangProps) => ({
        // label: `${item.nomeCasal}, ${item.anb}`,
        label: ['CC', 'RC'].includes(activity.tscod)
          ? `${item.nomeCasal}, ${item.loccod}`
          : `${item.nomeCasal}, ${item.anb}`,
        value: item.seq,
      })),
    );
  }, [
    activity.ofiguia,
    activity.seq,
    activity.svcaplic,
    activity.tscod,
    user.anbc,
  ]);

  const getLocalServ = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLocalServ.php${
        ['ZON', 'INT'].indexOf(user.perfil) > -1
          ? `?data=${JSON.stringify({ getZon: true })}`
          : ''
      }`,
    );

    setComboLocalServ(
      response.data.map((item: ComboLocalServProps) => ({
        value: item.cod,
        label: `${item.nome}, ${item.cid}`,
      })),
    );

    if (['4', '5', '6'].indexOf(activity.prqcod) < 0) {
      const index = response.data.findIndex(
        (item: ComboLocalServProps) => item.cod === activity.prqcod,
      );

      setSelectedLocalServ(response.data[index].cod);
    }
  }, [activity.prqcod, user.perfil]);

  useEffect(() => {
    if (
      !(
        location.pathname.includes('atividades') ||
        location.pathname.includes('servicos')
      )
    ) {
      history.replace(
        `${location.pathname.substring(
          0,
          location.pathname.indexOf('secretaria') + 10,
        )}/not-found`,
      );
    }
    if (!location.state) {
      history.goBack();
    }

    if (activity.type === 'O') {
      const weekIndex = semana.findIndex(
        (item: ComboProps) => item.value === activity.diasemraw,
      );

      setInitSemana(semana[weekIndex].label);
    }

    if (['CG', 'GC', 'CO'].indexOf(activity?.tscod) < 0) {
      getLocalServ();
    }

    let deconvertTema = '';

    if (activity.tema) {
      deconvertTema = deconvertSpecialChars(activity.tema);
    }
    formRef.current?.setData({
      comboGuia: activity.guia1,
      comboGuia2: activity.guia2 || undefined,
      comboGevang:
        activity.tscod === 'CC' ? activity.ofiguia : activity.svcaplic,
      weekday: activity.diasemraw,
      hour: activity.hora,
      participantes:
        ['CO', 'CB', 'ED', 'JE', 'RC', 'RD', 'PE'].indexOf(activity?.tscod) > -1
          ? activity.qteServ
          : activity.qtpart,
      tema: deconvertTema,
      dtini: activity.dtini,
      dtfim: activity.dtfim,
      localserv: activity.prqcod,
    });

    if (['JE', 'PE', 'RD'].indexOf(activity.tscod) > -1) {
      if (user.anbc !== activity.orig.anb) {
        setSelectedANB(activity.orig.anb);
        getANBs();
        getOtherGuias(activity.orig.anb);
        return;
      }
      if (user.loccod !== activity.orig.loc) {
        setSelectedANB('Selecione');
        getGuias('byNac');
        return;
      }
    }

    if (['CC', 'ED', 'RE', 'RC'].indexOf(activity.tscod) < 0) {
      getGuias();
    } else {
      getGevangs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGuiaSelect = useCallback(() => {
    const guia = formRef.current?.getFieldValue('comboGuia');
    const guia2 = formRef.current?.getFieldValue('comboGuia2');

    if (guia === guia2) {
      formRef.current?.setFieldValue('comboGuia2', '');
      setInitialG2('Selecione');
    }
    setComboGuia2(
      guias
        .filter((item: ComboGuiaProps) => item.seq !== guia)
        .map((item: ComboGuiaProps) => ({
          value: item.seq,
          label: item.nome,
        })),
    );
  }, [guias]);

  const handleGuiaSelect2 = useCallback(() => {
    const g2 = formRef.current?.getFieldValue('comboGuia2');
    setInitialG2(
      comboGuia2[comboGuia2.findIndex((item: ComboProps) => item.value === g2)]
        .value,
    );
  }, [comboGuia2]);

  const handleSelectedANB = useCallback(() => {
    setComboGuia([]);
    getOtherGuias(formRef.current?.getFieldValue('comboANB'));
  }, [getOtherGuias]);

  const handlePartType = useCallback(() => {
    const type = formRef.current?.getFieldValue('partType');
    setPartType(type);
  }, []);

  const handleRadioClick = useCallback(() => {
    const orig = formRef.current?.getFieldValue('orig');
    formRef.current?.setFieldValue('comboGuia', '');

    setSelectedOrig(orig);

    if (orig === prevOrig) {
      formRef.current?.setFieldValue('comboGuia', activity.guia1);
      if (orig === 'L' || orig === 'N') {
        setComboGuia(
          enterGuias.map((item: ComboGuiaProps) => ({
            value: item.seq,
            label: orig === 'L' ? item.nome : `${item.nome}, ${item.loc}`,
          })),
        );
      } else {
        getANBs();
        setComboGuia(
          enterGuias.map((item: ComboGuiaProps) => ({
            value: item.seq,
            label: `${item.nome}, ${item.loc}`,
          })),
        );
      }
      formRef.current?.setFieldValue('comboGuia', activity.svcaplic);
      const index = enterGuias.findIndex(
        (item: ComboGuiaProps) => item.seq === activity.svcaplic,
      );

      setInitialG(enterGuias[index].seq);
    } else {
      setInitialG('Selecione');
      setComboGuia([]);

      if (orig === 'L') {
        getGuias();
      } else if (orig === 'N') {
        getGuias('byNac');
      } else {
        getANBs();
        formRef.current?.setFieldValue('comboGuia', '');
      }
    }
  }, [
    prevOrig,
    activity.guia1,
    activity.svcaplic,
    enterGuias,
    getANBs,
    getGuias,
  ]);

  const handleDateSelection = useCallback(
    () => {
      formRef.current?.setFieldError('dtini', '');
      const date = formRef.current?.getFieldValue('dtini').padStart(10, 0);
      const parsed = new Date(parseISO(date));

      if (!isValid(parsed)) {
        formRef.current?.setFieldError('dtini', 'Data inválida.');

        return;
      }

      const allowedDateResult = serviceAllowedDate(date);
      if (!allowedDateResult.status) {
        formRef.current?.setFieldError('dtini', allowedDateResult.motive);
        formRef.current?.setFieldValue('dtini', null);
        formRef.current?.setFieldValue('dtfim', null);
        return;
      }

      formRef.current?.setFieldError('dtini', '');

      // const dtfimRef = formRef.current?.getFieldRef('dtfim');
      // const prevVal = dtfimRef.value;
      // if (!isValid(parsed)) {
      //   setInitSemana('Selecione o dia da semana');
      //   return;
      // }
      // const weekday = getDay(parsed);
      // formRef.current?.setFieldValue('weekday', weekday);
      // setInitSemana(
      //   semana[
      //     semana.findIndex(
      //       (item: ComboProps) => parseInt(item.value, 10) === weekday,
      //     )
      //   ].label,
      // );
      // let daystoadd = 0;
      // if (['SA', 'TA'].indexOf(activity.tscod) > -1) {
      //   daystoadd = 110;
      // }
      // if (['SJ', 'TC', 'TD', 'TJ'].indexOf(activity.tscod) > -1) {
      //   daystoadd = 90;
      // }
      // if (activity.tscod === 'ED') {
      //   daystoadd = 6;
      // }
      // if (['AI', 'AN', 'RE', 'PE'].indexOf(activity.tscod) > -1) {
      //   daystoadd = 3;
      // }
      // if (activity.tscod === 'CB') {
      //   daystoadd = 30;
      // }
      // if (activity.tscod === 'CC') {
      //   daystoadd = 42;
      // }
      // const added = addDays(parsed, daystoadd);
      // formRef.current?.setFieldValue('dtfim', format(added, 'yyyy-MM-dd'));
      // if (['CG', 'CO', 'GC'].indexOf(activity.tscod) > -1) {
      //   const year = parsed.getUTCFullYear();
      //   const month = getMonth(parsed);
      //   formRef.current?.setFieldValue(
      //     'dtfim',
      //     month < 6 ? `${year}-06-30` : `${year}-12-31`,
      //   );
      // }
      // if (prevVal === '') {
      //   dtfimRef.focus();
      // }
    },
    [
      /* semana, activity.tscod */
    ],
  );

  const handleResetField = useCallback(() => {
    formRef.current?.setFieldValue('comboGuia2', '0');
    setInitialG2('Selecione');
  }, []);

  const compareValidDate = useCallback((min: string, valid: string) => {
    const mindate = handleTimeZone(min);
    const validthrough = handleTimeZone(valid);

    const formats = 'YYYY-mm-dd';

    const mdmoment = moment(mindate, formats);
    const vtmoment = moment(validthrough, formats);

    return mdmoment.isBefore(vtmoment);
  }, []);

  const handleSubmit = useCallback(
    async (data: UpdateFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        // const parsedDate = new Date(parseISO(data.dtini));

        const date = formRef.current?.getFieldValue('dtini').padStart(10, 0);
        const parsed = new Date(parseISO(date));
        if (!isValid(parsed)) {
          formRef.current?.setFieldError('dtini', 'Data inválida');
          setLoading(false);
          return;
        }

        if (new Date(data.dtini).getTime() > new Date(data.dtfim).getTime()) {
          formRef.current?.setFieldError(
            'dtfim',
            'Data final deve ser maior do que a data de início',
          );
          setLoading(false);
          return;
        }

        const parsedDate = stringToDate(data.dtini);
        const base = generateTodayV2();
        const semester = base.getMonth() < 6 ? 1 : 2;
        let minINIDate = generateTodayV2();
        minINIDate.setDate(1);
        minINIDate.setMonth(semester === 1 ? 0 : 6);
        minINIDate = subDays(minINIDate, 1);

        const maxINIDate = addDays(
          addYears(
            new Date(
              `${new Date(new Date().toISOString()).getFullYear()}-12-31`,
            ),
            1,
          ),
          1,
        );

        if (['CC', 'ED', 'RE'].indexOf(data.tpserv) > -1) {
          if (!data.comboGevang) {
            formRef.current?.setFieldError('comboGevang', ` `);
            setLoading(false);
            return;
          }

          const index = gevangs
            .map((item) => item.seq)
            .indexOf(data.comboGevang);

          if (
            data.tpserv !== 'CC' &&
            !compareValidDate(data.dtini, gevangs[index].valid)
          ) {
            formRef.current?.setFieldError('comboGevang', ` `);
            let message;
            if (data.tpserv === 'CC') {
              message = 'Casal Evangelizador';
            } else if (data.tpserv === 'ED') {
              message = 'Casal Evangelizador de EED';
            } else {
              message = 'Animador no Espirito';
            }
            addToast({
              type: 'error',
              title: 'Evangelizador expirado',
              description: `${message} com data de validade expirada`,
              seconds: 20,
            });
            setLoading(false);
            return;
          }
        }

        let daystoadd = 0;

        if (['SA', 'TA'].indexOf(activity.tscod) > -1) {
          daystoadd = 110;
        }
        if (['SJ', 'TC', 'TD', 'TJ'].indexOf(activity.tscod) > -1) {
          daystoadd = 70;
        }
        if (activity.tscod === 'ED') {
          daystoadd = 6;
        }
        if (['AI', 'AN', 'RE', 'PE'].indexOf(activity.tscod) > -1) {
          daystoadd = 3;
        }
        if (activity.tscod === 'CB') {
          daystoadd = 30;
        }
        if (activity.tscod === 'CC') {
          daystoadd = 42;
        }

        if (!isValid(parsedDate)) {
          formRef.current?.setFieldError('dtini', 'Este campo é obrigatório');
          setLoading(false);
          return;
        }

        if (
          parsedDate.getFullYear() <
          new Date(new Date().toISOString()).getFullYear()
        ) {
          formRef.current?.setFieldError(
            'dtini',
            `A data de início deve ser posterior a ${format(
              minINIDate,
              'dd/MM/yyyy',
            )}`,
          );
          setLoading(false);
          return;
        }

        let ENDDate = addDays(parsedDate, daystoadd);

        if (['CG', 'CO', 'GC'].indexOf(activity.tscod) > -1) {
          const year = parsedDate.getUTCFullYear();
          const month = getMonth(parsedDate);
          ENDDate = new Date(
            parseISO(month < 6 ? `${year}-06-30` : `${year}-12-31`),
          );
        }

        const schema = yup.object().shape({
          tpserv: yup.string().notOneOf(['Selecione', '']),
          comboGuia: yup.string().notOneOf(['Selecione', '']),
          // comboGuia2: yup.string().nullable(true),
          comboGuia2: yup.string().when('tpserv', {
            is: (val) => ['TC', 'TD'].indexOf(val) > -1,
            then: yup.string().notOneOf(['Selecine', '']),
            otherwise: yup.string().nullable(true),
          }),
          localserv: yup.string().when('partType', {
            is: (type) => type === 'V',
            then: yup.string().nullable(true),
            otherwise: yup.string().notOneOf(['Selecione', '']),
          }),
          // localserv: yup.string().notOneOf(['Selecione', '']),
          dtini: yup
            .date()
            .typeError('Data inválida.')
            .min(
              minINIDate,
              `A data de início deve ser posterior a ${format(
                minINIDate,
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              maxINIDate,
              `A data de início deve ser anterior a ${format(
                addDays(maxINIDate, 1),
                'dd/MM/yyyy',
              )}`,
            ),
          dtfim: yup.date().when('tpserv', {
            is: (val) => ['CO', 'CG', 'GC'].indexOf(val) > -1,
            then: yup
              .date()
              .typeError('Data inválida.')
              .min(`${new Date(data.dtini).getFullYear()}-01-01`)
              .max(
                ENDDate,
                'O encerramento deve ser dentro do semestre de cadastro.',
              ),
            otherwise: yup
              .date()
              .typeError('Data inválida.')
              .min(subDays(ENDDate, 10), 'Data fora do período pré-determinado')
              .max(
                addDays(ENDDate, 10),
                'Data fora do período pré-determinado',
              ),
          }),

          weekday: yup.string().notOneOf(['Selecione o dia da semana', '']),
          hour: yup.string().when('tpserv', {
            is: (val) => ['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(val) > -1,
            then: yup.string().required('Este campo é obrigatório'),
            otherwise: yup.string().nullable(true),
          }),
          participantes: yup
            .number()
            .typeError('Quantidade inválida')
            .min(
              0,
              'Não é possível inserir uma quantidade negativa de participantes.',
            ),
          tema: yup.string().when('tpserv', {
            is: (val) => ['JE', 'PE', 'RD', 'RC'].indexOf(val) > -1,
            then: yup.string().nullable(true),
            otherwise: yup
              .string()
              .trim()
              .min(
                5,
                'Quantidade mínima de caracteres válidos não atingidos (5).',
              ),
          }),
          comboServCoordGUIA: yup.string().when('tpserv', {
            is: (val) => ['ED', 'RE'].indexOf(val) > -1,
            then: yup.string().required('Este campo é obrigatório'),
            otherwise: yup.string().nullable(true),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newInfo = {
          ...data,
          tpserv: activity.tscod,
          seq: activity.seq,
          comboGuia2: data.comboGuia2 || '0',
          tema: data.tema ? convertSpecialChars(data.tema) : '',
          tpactivity: activity.type,
        } as any;
        if (!['ED', 'RE'].includes(newInfo.tpserv)) {
          newInfo.comboServCoordGUIA = null;
        }

        // await api.get(
        //   `/sgo/atividades_update.php?data=${JSON.stringify(send)}`,
        // );

        const send = new FormData();
        send.append('data', JSON.stringify(newInfo));

        await api.post('/sgo/atividades_update.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Atividade ${activity.type}-${activity.seq} alterada!`,
          seconds: 8,
        });

        // const setStorage = {
        //   year: `${parsedDate.getFullYear()}`,
        //   semester: addDays(parsedDate, 1).getUTCMonth() < 6 ? '1' : '2',
        //   tscod: activity.tscod,
        //   tsdesc: activity.tsdesc,
        //   anbcod: user.anbc,
        //   anbdesc: user.anbdesc,
        //   loccod:
        //     ['AN', 'AI', 'JF'].indexOf(activity.tscod) >= 0 ? '' : user.loccod,
        //   locdesc:
        //     ['AN', 'AI', 'JF'].indexOf(activity.tscod) >= 0
        //       ? 'Todas da Nacional'
        //       : user.locdesc,
        //   cod:
        //     ['AN', 'AI', 'JF'].indexOf(activity.tscod) >= 0
        //       ? user.anbc
        //       : user.loccod,
        //   desc:
        //     ['AN', 'AI', 'JF'].indexOf(activity.tscod) >= 0
        //       ? user.anbdesc
        //       : user.locdesc,
        // };

        // deleteLocalStorageItemKey(`${process.env.REACT_APP_SERVICE}`, [
        //   'zoncod',
        //   'zondesc',
        //   'anbcod',
        //   'anbdesc',
        // ]);

        localStorage.removeItem(`${lsConst}`);

        // setLocalStorage(`${lsConst}`, setStorage);
        // if (['GUI', 'LOC'].indexOf(user.perfil) >= 0) {
        //   deleteLocalStorageItemKey(`${lsConst}`, [
        //     'zoncod',
        //     'zondesc',
        //     'anbcod',
        //     'anbdesc',
        //   ]);
        // }

        history.replace(location.pathname.replace('/update', ''));

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      activity.seq,
      activity.tscod,
      activity.type,
      addToast,
      compareValidDate,
      errorHandling,
      gevangs,
      history,
      location.pathname,
      lsConst,
    ],
  );

  // new functions
  const [initialCoordCombos, setInitialCoordCombos] = useState(() => {
    return activity.svccoor
      ? {
          anb: activity.svccoor.anbcod,
          loc: activity.svccoor.loccod,
          guia: activity.svccoor.seq,
        }
      : { anb: '', loc: '', guia: '' };
  });
  const getCoordsForCoord = useCallback(async () => {
    try {
      setCoordANBS([]);
      setFilteredCoordLOCS([]);
      setFilteredCoordGUIAS([]);
      await api
        .get(`/combos/serviceCoordCombos.php?seq=${activity.seq}&type=update`)
        .then((response) => {
          setCoordANBS(response.data.anbs);
          setCoordLOCS(response.data.locs);
          setCoordGUIAS(response.data.guias);

          return response;
        })
        .then((response) => {
          const anb = activity.svccoor.anbcod;
          const loc = activity.svccoor.loccod;
          setFilteredCoordLOCS(
            response.data.locs.filter((item: any) => item.anb === anb),
          );

          const currGevang = gevangs.findIndex(
            (item) => item.seq === activity.svcaplic,
          );
          // const { seq1, seq2 } = gevangs[currGevang];

          setFilteredCoordGUIAS(() => {
            let filtered = response.data.guias.filter(
              (item: any) => item.anb === anb && item.loc === loc,
            );
            if (currGevang > -1) {
              const { seq1, seq2 } = gevangs[currGevang];

              filtered = filtered.filter(
                (item: any) => ![seq1, seq2].includes(item.gseq),
              );
            }
            return filtered;
          });
        });
    } catch (err) {
      console.log(err);
    }
  }, [activity.svccoor, activity.svcaplic, gevangs, activity.seq]);

  const handleCoordSelection = useCallback(
    (ev) => {
      const { value, dataset } = ev.currentTarget;
      setInitialCoordCombos((state) =>
        dataset.type === 'nac'
          ? { ...state, loc: 'Selecione', guia: 'Selecione' }
          : { ...state, guia: 'Selecione' },
      );

      if (dataset.type === 'nac') {
        setFilteredCoordLOCS(coordLOCS.filter((item) => item.anb === value));
        setFilteredCoordGUIAS([]);
      }
      if (dataset.type === 'loc') {
        const select = formRef.current?.getFieldRef('comboServCoordANB');
        const anb = select.options[select.selectedIndex].value;

        const gevangSelect = formRef.current?.getFieldRef('comboGevang');
        const currGevang = gevangs.findIndex(
          (item) =>
            item.seq === gevangSelect.options[gevangSelect.selectedIndex].value,
        );

        setFilteredCoordGUIAS(() => {
          let filtered = coordGUIAS.filter(
            (item) => item.anb === anb && item.loc === value,
          );
          if (currGevang > -1) {
            const { seq1, seq2 } = gevangs[currGevang];

            filtered = filtered.filter(
              (item) => ![seq1, seq2].includes(item.gseq),
            );
          }
          return filtered;
        });
      }
    },
    [coordLOCS, coordGUIAS, gevangs],
  );

  useEffect(() => {
    if (['ED', 'RE'].includes(activity.tscod)) {
      getCoordsForCoord();
    }
  }, [activity.tscod, getCoordsForCoord]);

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        isResponsible
        noLinks
        title={`Alteração ${activity?.tsdesc} | ${activity?.type}-${activity.seq}`}
      />
      <Content>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ tpserv: activity.tscod }}
        >
          <Input name="tpserv" isHidden />
          <InfoContainer>
            {!!activity?.tscod &&
              ['JE', 'PE', 'RD'].indexOf(activity?.tscod) > -1 && (
                <>
                  <span>
                    <p>Selecione a origem do aplicador:</p>
                    <RadioContainer
                      title=""
                      onClick={handleRadioClick}
                      name="orig"
                      content={[
                        { id: 'aL', value: 'L', label: 'Local' },
                        { id: 'aN', value: 'N', label: 'Nacional' },
                        { id: 'oN', value: 'E', label: 'Outra Nacional' },
                      ]}
                      selected={selectedOrig}
                      isDisabled={user.perfil !== 'LOC'}
                    />
                  </span>

                  <animated.span style={otherNac}>
                    <p>Nacional</p>
                    <SelectV2
                      name="comboANB"
                      content={comboANBs}
                      disabled={selectedOrig !== 'E'}
                      initial={selectedANB}
                      onChange={handleSelectedANB}
                    />
                  </animated.span>

                  <span>
                    <p>Guia Aplicador</p>
                    <SelectV2
                      name="comboGuia"
                      content={comboGuia}
                      initial={initialG}
                      disabled={user.perfil !== 'LOC'}
                    />
                  </span>
                </>
              )}
            {!!activity?.tscod &&
              ['CC', 'ED', 'RE', 'RC'].indexOf(activity?.tscod) > -1 && (
                <span>
                  <p>
                    {['CC', 'RC'].indexOf(activity?.tscod) > -1
                      ? 'Casal Coordenador'
                      : activity?.tscod === 'ED'
                      ? 'Casal Evangelizador'
                      : 'Animador no Espírito'}
                  </p>
                  <SelectV2
                    name="comboGevang"
                    content={comboGevang}
                    initial={initialGEvang}
                  />
                  {activity?.tscod === 'CC' && (
                    <figure>
                      <p>
                        * Se o Casal Coordenador não estiver na lista, é
                        necessário solicitar à Coordenação Nacional o
                        cadastramento.
                      </p>
                    </figure>
                  )}
                </span>
              )}
            {['ED', 'RE'].includes(activity.tscod) ? (
              <>
                <span>
                  <p>ANB do Coordenador</p>
                  <SelectV2
                    name="comboServCoordANB"
                    content={coordANBS.map((item) => ({
                      value: item.anb,
                      label: item.anbdesc,
                    }))}
                    data-type="nac"
                    onChange={handleCoordSelection}
                    initial={initialCoordCombos.anb}
                    disabled={activity.ofiguia !== null}
                  />
                </span>
                <span>
                  <p>Local do Coordenador</p>
                  <SelectV2
                    name="comboServCoordLOC"
                    content={filteredCoordLOCS.map((item) => ({
                      value: item.loc,
                      label: item.locdesc,
                    }))}
                    initial={initialCoordCombos.loc}
                    data-type="loc"
                    onChange={handleCoordSelection}
                    disabled={activity.ofiguia !== null}
                  />
                </span>
                <span>
                  <p>Guia Coordenador</p>
                  <SelectV2
                    name="comboServCoordGUIA"
                    content={filteredCoordGUIAS.map((item) => ({
                      value: item.gseq,
                      label: item.name,
                    }))}
                    initial={initialCoordCombos.guia}
                    disabled={activity.ofiguia !== null}
                  />
                </span>
              </>
            ) : null}
            {!!activity?.tscod &&
              [
                'AI',
                'CC',
                'RC',
                'ED',
                'CG',
                'GC',
                'RE',
                'JA',
                'JE',
                'PE',
                'RD',
                'AN',
                'JF',
              ].indexOf(activity?.tscod) < 0 && (
                <>
                  <span>
                    <p>
                      {['SA', 'SJ'].indexOf(activity?.tscod) > -1 && 'Futuro '}
                      Guia {activity?.tscod === 'CO' && ' Animador'}
                    </p>
                    <SelectV2
                      name="comboGuia"
                      initial={initialG}
                      content={comboGuia}
                      disabled={
                        user.tgcod === 'FG' ||
                        (user.perfil === 'GUI' &&
                          ['SA', 'SJ'].indexOf(activity?.tscod) < 0)
                        // (user.perfil !== 'LOC' &&
                        //   ['SA', 'SJ'].indexOf(activity?.tscod) > -1) ||
                        // user.tgcod === 'FG'
                      }
                      onChange={handleGuiaSelect}
                    />
                  </span>
                  {/* anteriormente: ['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf( activity?.tscod, ) > -1 */}
                  {(['TC', 'TD'].indexOf(activity?.tscod) > -1 ||
                    (['SA', 'SJ', 'TA', 'TJ'].indexOf(activity?.tscod) > -1 &&
                      user.perfil !== 'GUI')) && (
                    <span>
                        <p>
                        {['SA', 'SJ'].indexOf(activity?.tscod) > -1 &&
                          'Futuro '}
                        Guia
                        {['TC', 'TD'].indexOf(activity?.tscod) > -1 &&
                          ' Acompanhante '}
                      </p>
                        <SelectV2
                        name="comboGuia2"
                        initial={initialG2}
                        content={comboGuia2}
                        onChange={handleGuiaSelect2}
                      />

                        <ButtonRemove
                        type="button"
                        style={buttonStyle}
                        onClick={handleResetField}
                      >
                        <FaTimes /> Limpar Campo
                      </ButtonRemove>
                      </span>
                  )}
                </>
              )}
            {/* ['AN', 'CB', 'JA', 'JE', 'PE', 'RC', 'RD'] */}
            {['CB', 'JE', 'PE'].indexOf(activity.tscod) > -1 && (
              <span>
                <p>Participação</p>
                <RadioContainer
                  title=""
                  onClick={handlePartType}
                  name="partType"
                  content={[
                    { id: 'pP', value: 'P', label: 'Presencial' },
                    { id: 'pV', value: 'V', label: 'Virtual' },
                  ]}
                  selected={partType}
                />
              </span>
            )}
            {['CG', 'GC', 'CO'].indexOf(activity?.tscod) < 0 && (
              <span>
                <p>Local de Serviço/Atividade</p>
                <SelectV2
                  name="localserv"
                  initial={selectedLocalServ}
                  content={comboLocalServ}
                  disabled={partType === 'V'}
                />
              </span>
            )}
            <span>
              <p>
                Data{' '}
                {['CC', 'SA', 'SJ', 'TA', 'TC', 'TD', 'TJ'].indexOf(
                  activity?.tscod,
                ) > -1
                  ? 'Reunião de Abertura'
                  : 'de Início'}
                {['JE', 'JA', 'RD', 'RC', 'PE'].indexOf(activity?.tscod) > -1 &&
                  ' e Encerramento'}
              </p>
              <Input
                name="dtini"
                type="date"
                placeholder="dd/mm/aaaa"
                onBlur={handleDateSelection}
              />
            </span>
            {['JE', 'JA', 'RD', 'RC', 'PE'].indexOf(activity?.tscod) < 0 && (
              <span>
                <p>Data de Encerramento</p>
                <Input
                  name="dtfim"
                  type="date"
                  placeholder="dd/mm/aaaa"
                  disabled={['CG', 'GC', 'CO'].indexOf(activity?.tscod) > -1}
                />
              </span>
            )}
            {!!activity?.tscod &&
              ['SA', 'SJ', 'TA', 'TC', 'TD', 'TJ', 'CC'].indexOf(
                activity?.tscod,
              ) > -1 && (
                <>
                  <span>
                    <p>Dia da Semana:</p>
                    <SelectV2
                      name="weekday"
                      initial={initSemana}
                      content={semana}
                    />
                  </span>
                  <span>
                    <p>Horário</p>
                    <Input name="hour" type="time" placeholder="hh:mm" />
                  </span>
                </>
              )}

            {!!activity?.tscod &&
              ['CO', 'CB', 'ED', 'JE', 'RC', 'RD', 'PE'].indexOf(
                activity?.tscod,
              ) > -1 && (
                <span>
                  <p>
                    Participantes <strong>NÃO Guias</strong>
                  </p>
                  <Input name="participantes" type="number" />
                </span>
              )}

            {!!activity?.tscod &&
              ['JE', 'PE', 'RE', 'RC', 'RD'].indexOf(activity?.tscod) > -1 && (
                <span>
                  <p>Tema</p>
                  <Input
                    name="tema"
                    mask="alpha"
                    placeholder="Tema do serviço"
                  />
                </span>
              )}
          </InfoContainer>
          <Button bgcolor="#00802b" type="submit" onClick={() => handleSubmit}>
            Alterar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default AtividadesUpdate;
