import React, { useRef, useState, useCallback, useEffect } from 'react';

import { Container, Content, ComboProps } from 'styles/sgo_wrappers';

import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Input from 'components/Input';
// import RadioContainer from 'components/RadioContainer';
import SelectV2 from 'components/SelectV2';
import Checkbox from 'components/Checkbox';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useSpring } from 'react-spring';
import { useCredentials } from 'hooks/credentials';

import * as yup from 'yup';

import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
// import { useChanges } from 'hooks/changes';
import api from 'services/api';
import Button from 'components/Button';
import getValidationErrors from 'utils/getValidationErrors';
import { format, isValid, parseISO, subDays } from 'date-fns';
import {
  formatDate,
  generateTodayV2,
  // handleTimeZone,
  serviceAllowedDate,
  // stringToDate,
} from 'utils/formatDate';
import { setLocalStorage } from 'utils/handleLocalStorage';

import {
  HeaderContainer,
  LocalServ,
  GuiasContainer,
  ListItem,
  Name,
  Cert,
  Check,
  GuiasContainerHeader,
  GuiasContainerBody,
  Wrapper,
} from './styles';

export interface GuiasProps {
  seq: number;
  name: string;
  cert: number;
  status: boolean;
}

export interface ComboLocalServProps {
  cod: string;
  nome: string;
  cid: string;
}

export interface FormData {
  date: string;
  localserv: string;
}

const DMGInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  // const { currDate } = useChanges();
  const history = useHistory();
  const { pathname } = useLocation();
  const { errorHandling } = useCredentials();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isVirtual, setIsVirtual] = useState(false);
  const [list, setList] = useState<GuiasProps[]>([]);
  const [guias, setGuias] = useState<number[]>([]);
  const [comboLocalServ, setComboLocalServ] = useState<ComboProps[]>([]);
  const [register, setRegister] = useState(false);
  const [existent, setExistent] = useState<string[]>([]);

  const getExistent = useCallback(async () => {
    const response = await api.get('/sgo/dmg_existent.php');
    setExistent(response.data);
  }, []);

  const getGuias = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/dmg_guias.php');
      setList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const getLocalServ = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLocalServ.php${
        ['ZON', 'INT'].indexOf(user.perfil) > -1
          ? `?data=${JSON.stringify({ getZon: true })}`
          : ''
      }`,
    );

    setComboLocalServ(
      response.data.map((item: ComboLocalServProps) => ({
        value: item.cod,
        label: `${item.nome}, ${item.cid}`,
      })),
    );
  }, [user.perfil]);

  useEffect(() => {
    getGuias();
    getLocalServ();
    getExistent();
  }, [getGuias, getLocalServ, getExistent]);

  // const handleRadioClick = useCallback(() => {
  //   const val = formRef.current?.getFieldValue('virtual');
  //   setIsVirtual(val !== 'P');
  // }, []);

  const handleCheckboxClick = useCallback(
    (seq: number) => {
      const index = guias.findIndex((item) => item === seq);

      if (index > -1) {
        setGuias(guias.filter((item) => item !== seq));
        return;
      }

      const newGuias = [...guias];
      newGuias.push(seq);
      setGuias(newGuias);
    },
    [guias],
  );

  const styledLserv = useSpring({
    opacity: isVirtual ? 0 : 1,
    pointerEvents: isVirtual ? 'none' : 'all',
  });

  const handleDate = useCallback(() => {
    const date = formRef.current?.getFieldValue('date');
    const rawDate = new Date(formRef.current?.getFieldValue('date'));
    // const { full } = currDate;

    formRef.current?.setFieldError('date', '');

    if (!isValid(rawDate) || !isValid(new Date(date))) {
      formRef.current?.setFieldValue('date', null);
      formRef.current?.setFieldError('date', 'Data inválida.');
      setRegister(false);
      return;
    }

    const allowedDateResult = serviceAllowedDate(date);
    if (!allowedDateResult.status) {
      formRef.current?.setFieldValue('date', null);
      formRef.current?.setFieldError('date', allowedDateResult.motive);
      setRegister(false);
      return;
    }

    // const eventDate = handleTimeZone(rawDate);
    //
    // const todayDate = full;

    // const formats = 'YYYY-mm-dd';

    // const eventMoment = moment(eventDate, formats);
    // const todayMoment = moment(todayDate, formats);

    setRegister(allowedDateResult.status);
  }, []);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const date = formRef.current?.getFieldValue('date');
        const allowedDateResult = serviceAllowedDate(date);
        if (!allowedDateResult.status) {
          formRef.current?.setFieldError('date', allowedDateResult.motive);
          formRef.current?.setFieldValue('date', null);
          return;
        }
        formRef.current?.setFieldError('date', '');

        const base = generateTodayV2();
        const semester = base.getMonth() < 6 ? 1 : 2;
        let parsedMinDate = generateTodayV2();
        parsedMinDate.setDate(1);
        parsedMinDate.setMonth(semester === 1 ? 0 : 6);
        parsedMinDate = subDays(parsedMinDate, 1);
        const parsedMaxDate = generateTodayV2();
        parsedMaxDate.setDate(31);
        parsedMaxDate.setMonth(11);
        parsedMaxDate.setFullYear(generateTodayV2().getFullYear() + 1);
        // minINIDate = subDays(minINIDate, 1);

        // const parsedMinDate = new Date(
        //   parseISO(`${new Date().getUTCFullYear()}-01-01`),
        // );

        // const parsedMaxDate = new Date(
        //   parseISO(`${new Date().getUTCFullYear() + 1}-12-31`),
        // );

        const schema = yup.object().shape({
          date: yup
            .date()
            .typeError('Data inválida.')
            .min(
              parsedMinDate,
              `O lançamento deve ser posterior a ${format(
                parsedMinDate,
                'dd/MM/yyyy',
              )}`,
            )
            .max(
              parsedMaxDate,
              `Data limite excedida (${formatDate(
                new Date(parsedMaxDate).toISOString(),
              )})`,
            ),
          localserv: yup.string().when('virtual', {
            is: (val) => val !== 'V',
            then: yup
              .string()
              .notOneOf(
                ['Selecione', ''],
                'Local de Serviço/Atividade deve ser informado',
              ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let received = data.date;
        if (parseInt(received.substr(-2), 10) === 1) {
          received = `${received.slice(0, -1)}2`;
        }

        const dmgdate = new Date(received);

        if (
          existent.includes(
            `${user.loccod}${dmgdate.getMonth() + 1}${dmgdate.getFullYear()}`,
          )
        ) {
          addToast({
            type: 'warning',
            title: 'Atenção',
            description:
              'Já existe um Dia Mensal do Guia cadastrado para este mês.',
          });
          setLoading(false);
          return;
        }

        const send = new FormData();
        const whichisit = formRef.current?.getFieldValue('virtual') === 'V';

        const newData = {
          data: data.date,
          localserv: whichisit
            ? user.zoncod === 'BC99'
              ? '6'
              : user.zoncod === 'BL99'
              ? '4'
              : '5'
            : data.localserv,
          guias: register ? [...guias] : [],
        };

        send.append('data', JSON.stringify(newData));

        const response = await api.post('/sgo/dmg_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const { status, message } = response.data;

        if (status) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description: message,
          });
          return;
        }

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Dia Mensal do Guia inserido.',
        });

        setLocalStorage(`${process.env.REACT_APP_DMG}`, {
          year: new Date(parseISO(data.date)).getFullYear().toString(),
        });

        history.replace(`${pathname.replace('/insert', '')}`);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err, err.response.data.message);
      }
    },
    [
      addToast,
      errorHandling,
      existent,
      guias,
      history,
      pathname,
      register,
      user.loccod,
      user.zoncod,
    ],
  );

  const shouldRegister = useSpring({
    opacity: register ? 1 : 0,
    pointerEvents: register ? 'all' : 'none',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar noLinks title="Novo Dia Mensal do Guia" isResponsible />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrapper>
            <HeaderContainer>
              <span>
                <p>Data</p>
                <Input
                  id="date"
                  name="date"
                  isDate
                  placeholder="dd/mm/aaaa"
                  onBlur={handleDate}
                />
              </span>

              {/* <span>
                <p>Participação</p>
                <RadioContainer
                  title=""
                  onClick={handleRadioClick}
                  name="virtual"
                  content={[
                    {
                      id: 'vP',
                      value: 'P',
                      label: 'Presencial',
                    },
                    {
                      id: 'vT',
                      value: 'V',
                      label: 'Virtual',
                    },
                  ]}
                  itemsStyle={{
                    flexDirection: 'row',
                  }}
                  selected="P"
                />
              </span> */}
              <LocalServ style={styledLserv}>
                <p>Local de Serviço/Atividade</p>
                <SelectV2
                  name="localserv"
                  initial="Selecione"
                  content={comboLocalServ}
                />
              </LocalServ>
            </HeaderContainer>
            <GuiasContainer style={shouldRegister}>
              <GuiasContainerHeader>
                <ListItem>
                  <Name>
                    <p>Guia</p>
                  </Name>
                  <Cert>
                    <p>Cert.</p>
                  </Cert>
                  <Check>
                    <p>Presença</p>
                  </Check>
                </ListItem>
              </GuiasContainerHeader>
              <GuiasContainerBody>
                {list.map((item, index) => (
                  <ListItem key={item.seq} paint={index}>
                    <Name>
                      <p>{item.name}</p>
                    </Name>
                    <Cert>
                      <p>{item.cert}</p>
                    </Cert>
                    <Check>
                      <Checkbox
                        id={`${item.seq}stat`}
                        title=""
                        containerStyle={{ margin: '0px' }}
                        onClick={() => handleCheckboxClick(item.seq)}
                      />
                    </Check>
                  </ListItem>
                ))}
              </GuiasContainerBody>
            </GuiasContainer>
          </Wrapper>

          <Button
            bgcolor="#00802b"
            type="submit"
            containerStyle={{ margin: '25px 0' }}
            disabled={!register}
          >
            Finalizar
          </Button>
        </Form>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default DMGInsert;
