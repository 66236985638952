import axios from 'axios';

const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    authorization: token,
    app_version: process.env.REACT_APP_VERSION,
  },
});

api.interceptors.request.use(
  function (config) {
    if (
      config.method === 'post' &&
      !Object.keys(config.headers).includes('content-type')
    ) {
      // Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
      // eslint-disable-next-line no-param-reassign
      config.headers['Content-Type'] = 'multipart/form-data';
    }

    // Do something before request is sent
    // console.log('interceptor used');

    // const user = localStorage.getItem(process.env.REACT_APP_USER);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default api;
